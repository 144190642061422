<template>
    <stripe-form v-show="loaded">
        <div class="o-account-stripe__stripe">
            <div class="o-account-stripe__line o-account-stripe__first">
                <div class="o-account-stripe__number">
                    <div class="o-account-stripe__label">
                        {{ trans('account.card.number') }}
                    </div>
                    <div ref="stripeCard"></div>
                    <stripe-input-field
                        field="cardNumber"
                        placeholder="0000 0000 0000 0000"
                        @ready="onReady"
                    ></stripe-input-field>
                </div>
                <div class="o-account-stripe__cvc">
                    <div class="o-account-stripe__label">
                        {{ trans('account.card.cvc') }}
                    </div>
                    <stripe-input-field
                        field="cardCvc"
                        inputClass="c-input-field__input--italic"
                        placeholder="000"
                        @ready="onReady"
                    ></stripe-input-field>
                </div>
            </div>
            <div class="o-account-stripe__line">
                <div class="o-account-stripe__half o-account-stripe__left">
                    <div class="o-account-stripe__label">
                        {{ trans('account.card.expiry') }}
                    </div>
                    <stripe-input-field
                        field="cardExpiry"
                        inputClass="c-input-field__input--italic"
                        placeholder="MM / YY"
                        @ready="onReady"
                    ></stripe-input-field>
                </div>
                <div class="o-account-stripe__half o-account-stripe__right">
                    <div class="o-account-stripe__label">
                        {{ trans('account.card.postal') }}
                    </div>
                    <input-field
                        inputClass="c-input-field__input--italic u-primary-color__focus"
                        placeholder="XXXXXX"
                        :modelValue="value"
                        @update:modelValue="$emit('input', $event)"
                    ></input-field>
                </div>
            </div>
        </div>
    </stripe-form>
</template>

<script>
import StripeInputField from '@js/vue-components/general/inputs/stripe-input-field.vue';

import StripeForm from '@js/vue-components/general/inputs/stripe-form.js';

export default {
    name: 'AccountStripe',
    components: {
        StripeForm,
        StripeInputField,
    },
    emits: ['cardCompleted', 'input', 'ready', 'stripeLoaded'],
    props: {
        value: String,
    },
    data() {
        return {
            cards: [],
        };
    },
    computed: {
        loaded() {
            return this.cards.length >= 3;
        },
        cardComplete() {
            return _.every(this.cards, '_complete');
        },
    },
    methods: {
        onReady(card) {
            this.cards.push(card);
            if (this.cards.length >= 3) {
                this.$emit('ready', this.cards[0]);
            }
        },
    },
    watch: {
        loaded(newValue) {
            if (newValue) {
                this.$emit('stripeLoaded');
            }
        },
        cardComplete() {
            this.$emit('cardCompleted', this.cardComplete);
        },
    },
};
</script>

<style lang="scss" scoped>
    .o-account-stripe {
        &__line {
            display: flex;
        }

        &__first {
            margin-bottom: 20px;
        }

        &__number {
            flex-grow: 1;
            margin-right: 16px;
        }

        &__cvc {
            width: 160px;
        }

        &__label {
            font-size: 10px;
            margin-bottom: 4px;
            text-transform: uppercase;
        }

        &__half {
            width: 50%;
        }

        &__left {
            padding-right: 8px;
        }

        &__right {
            padding-left: 8px;
        }

        @media (max-width: 550px) {
            &__line {
                flex-direction: column;
            }

            &__number {
                margin: 0 0 20px 0;
            }

            &__cvc {
                width: 100%;
            }

            &__half {
                padding: 0;
            }

            &__left {
                margin-bottom: 20px;
                width: 100%;
            }

            &__right {
                width: 100%;
            }
        }
    }
</style>
