<template>
    <div class="o-account-upgrade">
        <div class="o-account-upgrade__main">
            <div class="o-account-upgrade__top">
                <div class="o-account-upgrade__title">
                    {{ trans('account.' + selectedPlan + '.title') }}
                </div>
                <p class="o-account-upgrade__subtitle">
                    {{ trans('account.' + selectedPlan + '.subtitle') }}
                </p>
            </div>
            <div class="o-account-upgrade__methods">
                <account-methods
                    :paymentType="paymentType"
                    @newPayment="paymentType = $event"
                >
                    <template #paypalDisclaimer>
                        <div
                            v-if="billing.service && paymentType !== billing.service"
                            class="o-account-upgrade__disclaimer"
                        >
                            {{ trans('account.payment.paypal-disclaimer') }}
                        </div>
                    </template>
                </account-methods>
            </div>
            <div class="o-account-upgrade__methods">
                <account-payment-wrap
                    :key="paymentType"
                    v-model="agree"
                    :agree="agree"
                    :scheduleObject="scheduleObject"
                    :paymentType="paymentType"
                    :loggedIn="loggedIn"
                >
                </account-payment-wrap>
            </div>
        </div>
        <div class="o-account-upgrade__side u-primary-color__bg--messages">
            <div class="o-account-upgrade__plan">
                <account-schedule
                    :scheduleObject="scheduleObject"
                    :currencyList="currencyList"
                    @newSchedule="schedule = $event"
                    @setPayCurrency="setPayCurrency"
                >
                </account-schedule>

            </div>
            <div class="o-account-upgrade__features">
                <div class="o-account-upgrade__core u-primary-color__text">
                    {{ trans('account.membership-includes') }}
                </div>
                <div class="o-account-upgrade__listing">
                    <div
                        v-for="(feature, index) in planFeatures"
                        :key="index"
                        class="o-account-upgrade__feature"
                    >
                        <i
                            class="u-primary-color__text o-account-upgrade__icon"
                            :class="feature.icon"
                        >
                        </i>
                        <span>
                            {{ trans('account.' + selectedPlan + '.features.' + feature.text) }}
                        </span>
                    </div>
                </div>
                <slot name="viewPlans"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import AccountSchedule from './account-schedule.vue';
import AccountMethods from './account-methods.vue';
import AccountPaymentWrap from './account-payment-wrap.vue';

import { get } from 'vuex-pathify';
import providesCurrencies from '../../vue-mixins/providesCurrencies.js';
import providesPlansCurrencies from '../../vue-mixins/providesPlansCurrencies.js';

const premiumFeatures = [
    {
        text: '1',
        icon: 'far fa-briefcase',
    },
    {
        text: '2',
        icon: 'far fa-address-card',
    },
    {
        text: '3',
        icon: 'far fa-video-plus',
    },
    {
        text: '4',
        icon: 'far fa-chart-network',
    },
    {
        text: '5',
        icon: 'far fa-handshake-alt',
    },
    {
        text: '6',
        icon: 'far fa-user-circle',
    },
];

const platinumFeatures = [
    {
        text: '1',
        icon: 'far fa-briefcase',
    },
    {
        text: '2',
        icon: 'far fa-calendar-week',
    },
    {
        text: '3',
        icon: 'far fa-user-friends',
    },
    {
        text: '4',
        icon: 'far fa-video-plus',
    },
];

export default {
    name: 'AccountUpgrade',
    components: {
        'account-schedule': AccountSchedule,
        'account-methods': AccountMethods,
        'account-payment-wrap': AccountPaymentWrap,
    },
    mixins: [
        providesPlansCurrencies,
        providesCurrencies,
    ],
    emits: ['close', 'closeUpgradeModal'],
    props: {
        chosenPlan: {
            type: String,
            required: false,
        },
        loggedIn: Boolean,
    },
    data() {
        return {
            schedule: 'yearly',
            paymentType: 'stripe',
            agree: false,
            selectedPlan: this.chosenPlan,
        };
    },
    computed: {
        isStripe() {
            return this.paymentType === 'stripe';
        },
        schedulePrice() {
            return this.currentSchedule[this.schedule];
        },
        scheduleObject() {
            return {
                period: this.schedule,
                symbol: this.setCurrencySymbol,
                price: this.schedulePrice,
                currency: this.setCurrency,
                name: this.selectedPlan,
            };
        },
        planFeatures() {
            if (this.selectedPlan === 'platinum') {
                return platinumFeatures;
            }
            if (this.selectedPlan === 'premium') {
                return premiumFeatures;
            }
        },
        billing: get('user/billing', false),
    },
    methods: {
        closeModal() {
            this.$emit('closeUpgradeModal');
            this.$emit('close');
        },
    },
    created() {
        this.premiumFeatures = premiumFeatures;
        this.platinumFeatures = platinumFeatures;
    },
};
</script>

<style lang="scss" scoped>

    .o-account-upgrade {
        display: flex;
        height: 100%;

        &__main {
            flex-grow: 1;
        }

        &__top {
            padding: 30px 50px;
        }

        &__title {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        &__subtitle {
            max-width: 600px;
        }

        &__methods {
            padding: 30px 50px;
        }

        &__disclaimer {
            color: #666;
            font-size: 14px;
            padding-top: 15px;
        }

        &__side {
            width: 350px;
        }

        &__plan {
            margin-bottom: 20px;
            padding: 20px 14px;
        }

        &__features {
            padding: 20px;
        }

        &__feature {
            display: flex;
            margin-bottom: 10px;
        }

        &__icon {
            font-size: 18px;
            margin: 2px 16px 0 0;
            min-width: 22.5px;
            text-align: center;
        }

        &__core {
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 20px;
        }

        &__listing {
            color: #666;
            font-size: 16px;
            margin-bottom: 40px;
        }

        &__button {
            display: flex;
            justify-content: center;
        }

        &__read {
            background-color: #fff;
            border: 1px solid;
            font-size: 12px;
            padding: 6px 16px;
            text-transform: uppercase;
        }

        @media (max-width: 880px) {
            flex-direction: column;

            &__side {
                order: 1;
                width: 100%;
            }

            &__main {
                order: 2;
            }
        }

        @media (max-width: 450px) {
            &__top {
                padding: 30px;
            }

            &__methods {
                padding: 20px 30px;
            }
        }
    }
</style>
