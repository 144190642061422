<template>
    <div class="o-account-plans u-primary-color__border">

        <div class="o-account-plans__header">
            <div class="o-account-plans__title">
                {{ ( trans('account.plans.title')) }}
            </div>
            <div class="o-account-plans__subtitle">
                {{ ( trans('account.plans.subtitle')) }}
            </div>
        </div>

        <div
            v-blur="closeCurrencyDropdown"
            class="o-account-plans__switcher"
        >
            <div
                class="o-account-plans__circle u-hover--grow u-primary-color__border u-primary-color__text"
                @click="currencyDropdown = !currencyDropdown"
            >
                <span class="o-account-plans__selected">
                    {{ setCurrency }}
                </span>
                <i
                    class="fas o-account-plans__angle"
                    :class="currencyDropdown ? 'fa-angle-up' : 'fa-angle-down'"
                >
                </i>
            </div>
            <basic-popup
                v-show="currencyDropdown"
                :popupStyle="{ width: '60px', 'margin-top': '40px', padding: '0', border: '0', 'border-radius': '10px' }"
                triangleClass="u-hidden"
            >
                <div class="o-account-plans__dropdown u-primary-color__border u-primary-color__text">
                    <div
                        v-for="(code, index) in currencyList"
                        :key="index"
                        class="o-account-plans__code u-hover--grow"
                        @click="setPayCurrency(code)"
                    >
                        {{ code }}
                    </div>
                </div>
            </basic-popup>
        </div>

        <account-plan-table
            id="plans"
            :setCurrencySymbol="setCurrencySymbol"
            :setCurrency="setCurrency"
            :primaryButtonClass="'lowercase-button'"
            :secondaryButtonClass="'lowercase-button'"
            @upgrade="setUpgrade"
        >
        </account-plan-table>

    </div>
</template>

<script>

import { nextTick } from 'vue';
import accountPlanTable from './account-plan-table.vue';
import providesCurrencies from '@js/executives/vue-mixins/providesCurrencies.js';

export default {
    name: 'AccountPlans',
    components: {
        accountPlanTable,
    },
    mixins: [
        providesCurrencies,
    ],
    emits: ['upgrade'],
    props: {

    },
    data() {
        let planCookie = Cookie.get('selected-plan');
        if (typeof planCookie === 'undefined') {
            planCookie = '';
        } else {
            planCookie = JSON.parse(planCookie);
        }
        return {
            currencyDropdown: false,
            selectedPlan: planCookie,
        };
    },
    computed: {
    },
    methods: {
        closeCurrencyDropdown() {
            this.currencyDropdown = false;
        },
        setPayCurrency(code) {
            this.closeCurrencyDropdown();
            this.setCurrency = code;
        },
        setUpgrade(plan) {
            this.selectedPlan = plan;
            nextTick(() => {
                this.$emit('upgrade', plan);
            });
        },
    },
    watch: {
        'selectedPlan': {
            deep: true,
            immediate: true,
            handler(plan) {
                const planCookie = JSON.stringify(plan);
                Cookie.set('selected-plan', planCookie, { expires: 2 });
            },
        },
    },
    created() {
    },
};
</script>

<style lang="scss" scoped>
    .o-account-plans {
        background-color: #fff;
        border-top: 10px solid #f4f4f4;
        display: block;
        padding: 30px;

        &__header {
            margin-bottom: 20px;
            text-align: center;
        }

        &__title {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 15px;
        }

        &__subtitle {

        }

        &__switcher {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 20px;
            position: relative;
        }

        &__circle {
            align-items: center;
            background-color: #fff;
            border: 1px solid;
            border-radius: 20px;
            border-width: 2px;
            display: flex;
            font-size: 14px;
            font-weight: 600;
            padding: 4px 10px;
        }

        &__selected {
            display: block;
            min-width: 32px;
            width: 32px;
        }

        &__angle {
            margin-left: 3px;
        }

        &__dropdown {
            border: 2px solid;
            border-radius: 2px;
            font-size: 14px;
            padding: 4px 6px;
            text-align: center;
        }

        &__code {
            font-weight: 600;
        }
    }

</style>

<style lang="scss">

    .o-account-plans {
        .o-account-plan-table {
            &__feature {
                font-size: 15px;
            }

            &__name {
                font-size: 22px;
            }

            &__pricing {
                width: 171px;
            }

            &__price {
                font-size: 30px;
            }

            &__cta {
                margin: 18px 0 10px;
            }

            &__inactive {
                border: 1px solid;
            }

            &__symbol {

            }

            &__plan {
                padding: 0 30px;
            }

            &__item {
                font-size: 15px;
                min-height: 22px;
            }

            @media (max-width: 1600px) {
                &__plan {
                    padding: 0 15px;
                }
            }

            @media (max-width: 1100px) {
                &__plan {
                    padding: 0 10px;
                }
            }

            @media (max-width: 1065px) {
                &__plan {
                    padding: 0 5px;
                }
            }

            @media (max-width: 1000px) {
                &__large {
                    display: none;
                }

                &__feature {
                    width: 310px;
                }

                &__name {
                }

                &__pricing {
                    width: 185px;
                }

                &__item {
                    width: 105px;
                }

                &__resp {
                    display: flex;
                    flex-direction: column;
                }

            }

            @media (max-width: 590px) {
                &__plan {
                    padding: 0;
                }
            }

            @media (max-width: 550px) {
                &__feature {
                    width: 250px;
                }
            }

            @media (max-width: 480px) {
                &__feature {
                    width: 200px;
                }
            }

            @media (max-width: 400px) {
                &__feature {
                    width: 150px;
                }
            }

            @media (max-width: 350px) {
                &__feature {
                    width: 120px;
                }
            }
        }

    }

</style>