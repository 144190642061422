import getStripe from '@js/core/stripe.js';

export default {
    async created() {
        const stripe = await getStripe();
        this.elements = stripe.elements({
            fonts: [
                {
                    cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans:300,300i',
                },
            ],
        });
    },
    render() {
        return this.$slots.default ? this.$slots.default() : null;
    },
    provide() {
        return {
            elements: this.elements,
        };
    },
    data() {
        return {
            elements: null,
        };
    },
};
