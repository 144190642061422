<template>
    <div class="o-account-methods">
        <div class="o-account-methods__buttons">
            <div
                class="o-account-methods__method u-pointer"
                :class="{ 'o-account-methods__method--selected u-primary-color__border u-primary-color__bg--light': isStripe }"
                @click="$emit('newPayment', 'stripe')"
            >
                <i
                    class="fal fa-credit-card o-account-methods__card"
                    :class="{ 'u-primary-color__text': isStripe }"
                >
                </i>
                <div class="o-account-methods__label">
                    {{ trans('account.payment.pay-stripe') }}
                </div>
                <div
                    v-if="isStripe"
                    class="o-account-methods__tick u-primary-color__border"
                >
                    <i class="fal fa-check"></i>
                </div>
            </div>
            <!--            <div class="o-account-methods__method u-pointer"-->
            <!--                :class="{ 'o-account-methods__method&#45;&#45;selected u-primary-color__border u-primary-color__bg&#45;&#45;light' : !isStripe }"-->
            <!--                @click="$emit('newPayment', 'paypal')"-->
            <!--            >-->
            <!--                <i class="fab fa-paypal o-account-methods__card"-->
            <!--                    :class="{ 'u-primary-color__text' : !isStripe }"-->
            <!--                >-->
            <!--                </i>-->
            <!--                <div class="o-account-methods__label">-->
            <!--                    {{ trans('account.payment.pay-paypal') }}-->
            <!--                </div>-->
            <!--                <div class="o-account-methods__tick u-primary-color__border" v-if="!isStripe">-->
            <!--                    <i class="fal fa-check"></i>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
        <!-- <div class="o-account-methods__explanation">
            {{ isStripe ? 'Secure online payment with Visa, MasterCard or Amex. No account required.' : 'Safe checkout using Paypal. You can pay as a guest or log into your account.'}}
        </div> -->
        <slot name="paypalDisclaimer"></slot>
    </div>
</template>

<script>
export default {
    emits: ['newPayment'],
    props: {
        paymentType: String,
    },
    data() {
        return {

        };
    },
    computed: {
        isStripe() {
            return this.paymentType === 'stripe';
        },
    },
    methods: {

    },
};
</script>

<style lang="scss" scoped>
@import "@sass/mixins";

.o-account-methods {
    &__buttons {
        display: flex;
    }

    &__method {
        border: 1px solid #ddd;
        color: #999;
        margin-right: 16px;
        min-width: 120px;
        padding: 10px;
        position: relative;
        text-align: center;

        &--selected {
            color: #333;
        }
    }

    &__card {
        font-size: 30px;
        margin-bottom: 6px;
    }

    &__label {
        font-size: 10px;
        text-transform: uppercase;
    }

    &__tick {

        @include circle(26px, 14px, #333);
        position: absolute;
        right: -10px;
        top: -10px;
    }

    @media (max-width: 400px) {
        &__buttons {
            flex-direction: column;
        }

        &__method {
            margin-right: 8px;
        }
    }
}
</style>
