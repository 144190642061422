export const planOptions = {
    free: 'Free Executive Membership',
    premium: 'Premium Executive Membership',
    platinum: 'Platinum Executive Membership',
};

export const priceList = {
    platinum: {
        GBP: {
            yearly: 5000,
        },
        USD: {
            yearly: 6910,
        },
        EUR: {
            yearly: 5713,
        },
        CHF: {
            yearly: 6170,
        },
        AUD: {
            yearly: 8932,
        },
        CAD: {
            yearly: 8799,
        },
        SEK: {
            yearly: 57588,
        },
        ZAR: {
            yearly: 100979,
        },
        DKK: {
            yearly: 42511,
        },
        MXN: {
            yearly: 138463,
        },
        PLN: {
            yearly: 25717,
        },
    },
    premium: {
        GBP: {
            // monthly: 30,
            yearly: 300,
        },
        USD: {
            // monthly: 41.5,
            yearly: 415,
        },
        EUR: {
            // monthly: 34.3,
            yearly: 343,
        },
        CHF: {
            // monthly: 37,
            yearly: 370,
        },
        AUD: {
            // monthly: 53.6,
            yearly: 536,
        },
        CAD: {
            // monthly: 52.8,
            yearly: 528,
        },
        SEK: {
            // monthly: 345.5,
            yearly: 3455,
        },
        ZAR: {
            // monthly: 605.6,
            yearly: 6056,
        },
        DKK: {
            // monthly: 255.1,
            yearly: 2551,
        },
        MXN: {
            // monthly: 830.9,
            yearly: 8309,
        },
        PLN: {
            // monthly: 154.3,
            yearly: 1543,
        },
    },
    free: {
        GBP: {
            yearly: 0,
        },
        USD: {
            yearly: 0,
        },
        EUR: {
            yearly: 0,
        },
        CHF: {
            yearly: 0,
        },
        AUD: {
            yearly: 0,
        },
        CAD: {
            yearly: 0,
        },
        SEK: {
            yearly: 0,
        },
        ZAR: {
            yearly: 0,
        },
        DKK: {
            yearly: 0,
        },
        MXN: {
            yearly: 0,
        },
        PLN: {
            yearly: 0,
        },
    }
};

export default {
    data() {
        let planCookie = Cookie.get('selected-plan');
        if (typeof planCookie === 'undefined') {
            planCookie = '';
        } else {
            planCookie = JSON.parse(planCookie);
        }
        return {
            selectedPlan: planCookie,
        }
    },
    computed: {
        currentSchedule() {
            return priceList[this.selectedPlan][this.setCurrency];
        },
        plansPrices() {
            let prices = _.mapValues(priceList, this.setCurrency);
            return _.mapValues(prices, 'yearly');
        },
        // monthlyPrices() {
        //     let prices = _.mapValues(priceList, this.setCurrency);
        //     return _.mapValues(prices, 'monthly');
        // },
    },
    methods: {

    },
    watch: {
        'selectedPlan': {
            deep: true,
            immediate: true,
            handler(plan) {
                const planCookie = JSON.stringify(plan);
                Cookie.set('selected-plan', planCookie, { expires: 2 });
            }
        },
    }
}
