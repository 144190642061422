<!-- this component is currently being used by both purchase-payment-wrap and account-confirmation. Make sure both components work -->
<template>
    <div
        v-show="stripeLoaded || !isStripe"
        class="o-account-payment"
    >
        <slot
            name="confirmation"
            :promoCode="promoCode"
            :card="card"
            :postCode="postCode"
            :scheduleObject="finalScheduleObject"
        >
        </slot>
        <account-stripe
            v-if="isStripe"
            v-model="postCode"
            class="o-account-payment__stripe"
            @ready="card = $event"
            @stripeLoaded="stripeLoaded = true"
            @cardCompleted="cardCompleted = $event"
        >
        </account-stripe>
        <div
            class="o-account-payment__voucher"
            :class="{ 'o-account-payment__voucher--discount': discount }"
        >
            <spin-loader
                v-show="loading"
                class="c-spin-loader-overlay"
                :version="2"
            >
            </spin-loader>
            <div class="o-account-payment__input-container">
                <div
                    v-if="discount"
                    class="o-account-payment__verified u-primary-color__text"
                >
                    <i class="far fa-check"></i>
                </div>
                <input-field
                    v-model="promoCode"
                    inputClass="o-account-payment__voucher-input u-primary-color__focus u-primary-color__text u-primary-color__border"
                    :placeholder="trans('account.payment.promo')"
                    maxlength="50"
                    :disabled="!!discount"
                    :error="error"
                >
                </input-field>
                <div
                    v-if="!discount"
                    class="o-account-payment__apply u-primary-color__bg u-hover--grow"
                    @click="validateCode"
                >
                    {{ trans('account.payment.apply') }}
                </div>
                <div
                    v-else
                    class="o-account-payment__description u-primary-color__text"
                >
                    {{ description }}
                </div>
            </div>
        </div>
        <slot name="finalSum"></slot>
        <div class="o-account-payment__checkboxes">
            <radio-holder
                type="checkbox"
                radioBtnClass="c-radio-btn--thin c-radio-btn--sm"
                labelClass="c-radio-holder__label--lowercase"
                :labelStyle="{ 'font-size': '14px' }"
                :modelValue="agree"
                @update:modelValue="$emit('input', $event)"
            >
                I have read and accept the Not Actively Looking
                <router-link
                    class="o-account-payment__agree u-primary-color__text"
                    :to="{ name: 'terms', params: { tab: termsDestination } }"
                    target="_blank"
                >
                    {{ location === 'plans' ? trans('common.agreement') : trans('common.services-terms') }}
                </router-link>.
            </radio-holder>
        </div>
        <div class="o-account-payment__footer">
            <div
                class="o-account-payment__pay u-primary-color__bg u-hover--grow"
                :class="{ 'u-no-pointer u-obscure': cannotSubmit }"
                @click="$emit('submitPayment', { card: card, postCode: postCode })"
            >
                <slot name="submitText">
                    {{ trans('account.payment.review') }}
                </slot>
            </div>
        </div>
    </div>
</template>

<script>

import AccountStripe from './account-stripe.vue';
import knowsUpgrade from '../../vue-mixins/knowsUpgrade.js';
import { userStore } from '@js/home-nal/user/userStore.js';

export default {
    name: 'AccountPayment',
    components: {
        AccountStripe,
    },
    mixins: [
        knowsUpgrade,
    ],
    emits: ['applyDiscount', 'input', 'submitPayment'],
    props: {
        agree: Boolean,
        paymentType: String,
        scheduleObject: Object,
        location: String,
        form: {
            type: Object,
            default() {
                return {};
            },
        },
        loggedIn: Boolean,
    },
    data() {
        return {
            completed: 0,
            error: null,
            discount: null,
            promoCode: '',
            postCode: '',
            card: null,
            stripeLoaded: false,
            cardCompleted: false,
            loading: false,
        };
    },
    computed: {
        formCompleted() {
            if (!userStore.loggedIn()) {
                return !!this.form.firstname.trim()
                    && !!this.form.lastname.trim()
                    && !!this.form.email.trim();
            }
            return true;
        },
        isStripe() {
            return this.paymentType === 'stripe';
        },
        validCardDetails() {
            return this.cardCompleted;
        },
        cannotSubmit() {
            return !this.formCompleted || !this.agree || (this.isStripe && !this.validCardDetails);
        },
        finalSum() {
            if (!this.discount || !this.location) {
                return {
                    finalPrice: this.scheduleObject.price,
                };
            }
            return this[this.location + 'FinalSum']();
        },
        finalScheduleObject() {
            try {
                return {
                    ...this.scheduleObject,
                    ...this.finalSum,
                    discount: this.discount,
                };
            } catch (e) {
                return e.message;
            }
        },
        description() {
            if (!this.discount) {
                return ;
            }

            let key = this.location === 'services' ? ('services.' + this.discount[0]) : this.discount[this.scheduleObject.period];

            return trans('account.discount.' + key + '.description');
        },
        currency() {
            return this.scheduleObject.currency;
        },
        termsDestination() {
            if (this.location === 'plans') {
                return 'user-agreement';
            }
            if (this.location === 'services') {
                return 'services-terms';
            }
        },
        queryString() {
            if (this.location === 'services') {
                return '?services';
            }
            if (this.scheduleObject.name === 'premium' && this.scheduleObject.period === 'yearly') {
                return `?premium-yearly`;
            }
            if (this.scheduleObject.name === 'platinum') {
                return `?platinum&currency=${this.currency}`;
            }
            return '';
        },
        // periodChanged() {
        //     return this.promoCode === 'PREMIUM20' && this.scheduleObject.period === 'monthly';
        // }
    },
    methods: {
        async validateCode() {
            if (!this.promoCode) {
                return;
            }
            try {
                this.loading = true;
                const response = await this.get(`/api/executive/discount/${this.promoCode}` + this.queryString);
                if (!response.data) {
                    return;
                }
                this.discount = response.data.discount;
                this.error = null;
                this.$emit('applyDiscount', this.finalScheduleObject);
            } catch (error) {
                if (+error.status === 404) {
                    this.error = trans('account.discount.errors.missing');
                    this.discount = null;
                } else if (+error.status === 422) {
                    this.error = trans('account.discount.errors.invalid');
                } else {
                    throw error;
                }
            } finally {
                this.loading = false;
            }
        },
        plansFinalSum() {
            if (this.discount && this.scheduleObject.period === 'yearly') {
                if (this.discount.yearly === '50OFFONCE') {
                    return {
                        finalPrice: (this.scheduleObject.price / 2),
                    };
                }
                if (this.promoCode === 'PLATINUM20' || this.promoCode === 'PREMIUM20') {
                    return {
                        finalPrice: (this.scheduleObject.price * 0.8),
                    };
                }
                if (this.promoCode === 'PLATINUM1800') {
                    return {
                        finalPrice: 1800,
                    };
                }
                if (this.promoCode === 'PLATINUM2250') {
                    return {
                        finalPrice: 2250,
                    };
                }
            }
            return {
                finalPrice: this.scheduleObject.price,
            };
        },
        servicesFinalSum() {
            if (this.discount && this.discount[0] === 'XA8WPO') {
                return {
                    finalPrice: (this.scheduleObject.price / 2),
                };
            }
            if (this.discount && this.discount[0] === 'Q3JNM4') {
                return {
                    finalPrice: (this.scheduleObject.price * 0.8),
                };
            }
            return {
                finalPrice: this.scheduleObject.price,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@sass/mixins";
@import "@sass/variables";

.o-account-payment {
    &__cover {
        background-color: rgba(0, 0, 0, 0.3);
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: z('popup');
    }

    &__stripe {
        margin-bottom: 40px;
    }

    &__checkboxes {
        margin-bottom: 30px;
    }

    &__promo {
        align-items: center;
        border-bottom: 1px solid #ddd;
        color: #999;
        display: inline-flex;
        font-style: italic;
        margin-bottom: 40px;
        padding-bottom: 3px;
    }

    &__add {
        margin-left: 8px;
    }

    &__voucher {
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
        position: relative;
    }

    &__input-container {
        align-items: center;
        display: flex;
    }

    &__verified {

        @include circle(30px, 16px, #f4f4f4, #f4f4f4, #f4f4f4);
        left: -14px;
        position: absolute;
        top: -12px;
        z-index: z('over');
    }

    &__apply {
        color: #fff;
        font-size: 16px;
        margin-left: 16px;
        padding: 12px 20px;
    }

    &__description {
        font-weight: 600;
        margin-left: 16px;
    }

    &__agree {
        line-height: 22px;
        text-decoration: underline !important;
    }

    &__confirm {
        margin-bottom: 30px;
        padding: 16px;
    }

    &__footer {
        display: flex;
        justify-content: center;
    }

    &__pay {
        color: #fff;
        font-size: 13px;
        min-width: 200px;
        padding: 10px 30px;
        text-align: center;
        text-transform: uppercase;
    }

    @media (max-width: 550px) {
        &__voucher--discount {
            flex-wrap: wrap;
        }

        &__input-container {
            align-items: flex-start;
            flex-direction: column;
        }

        &__apply {
            margin: 15px 0 0;
        }

        &__description {
            margin: 15px 0 0;
        }
    }

    @media (max-width: 360px) {
    }

}
</style>
<style lang="scss">
    .o-account-payment {
        .o-account-payment__voucher-input {
            background-color: #fff !important;
            border: 2px dashed !important;
            font-weight: 600 !important;
            height: 50px  !important;
            padding: 0 18px !important;

            &::placeholder {
                color: #333 !important;
                font-weight: 300 !important;
            }

            &:focus {
                border: 2px dashed !important;
                outline: none;
            }

        }
    }
</style>
