<!-- The cover relies on no parent being position relative until the modal. It was the quickest way to make it work with Stripe plugin. May need refactoring later if it causes problems -->

<!-- This wrapper for account-payment.vue includes a confirmation screen -->
<template>
    <div class="o-account-payment-wrap">
        <account-payment
            :paymentType="paymentType"
            :scheduleObject="scheduleObject"
            :location="'plans'"
            v-bind="$attrs"
            :loggedIn="loggedIn"
            @submitPayment="confirmScreen"
        >
            <template #confirmation="{ promoCode, card, postCode, scheduleObject }">
                <div
                    v-if="viewConfirmation"
                    class="o-account-payment__cover"
                >
                    <account-confirmation
                        :paymentType="paymentType"
                        :scheduleObject="scheduleObject"
                        :promoCode="promoCode"
                        :card="card"
                        :postCode="postCode"
                        @closeConfirmation="closeConfirmation"
                    >
                    </account-confirmation>
                </div>
            </template>
        </account-payment>
    </div>
</template>

<script>

import AccountConfirmation from './account-confirmation.vue';
import AccountPayment from './account-payment.vue';

export default {
    components: {
        AccountConfirmation,
        AccountPayment,
    },
    mixins: [

    ],
    props: {
        paymentType: String,
        scheduleObject: Object,
        loggedIn: Boolean,
    },
    data() {
        return {
            viewConfirmation: false,
        };
    },
    computed: {
    },
    methods: {
        confirmScreen() {
            this.viewConfirmation = true;
            if (_.has(app.$refs, 'modal.seeClose')) {
                app.$refs.modal.seeClose = false;
            }
        },
        closeConfirmation() {
            this.viewConfirmation = false;
            if (_.has(app.$refs, 'modal.seeClose')) {
                app.$refs.modal.seeClose = true;
            }
        },
    },
    created() {

    },
};
</script>

<style lang="scss" scoped>
    .o-account-payment-wrap {

    }
</style>