<template>
    <div class="o-account-confirmation">
        <div class="o-account-confirmation__container">
            <div class="o-account-confirmation__box">
                <spin-loader
                    v-show="loading"
                    class="c-spin-loader-overlay"
                    :version="2"
                >
                </spin-loader>
                <div class="o-account-confirmation__return u-primary-color__bg--light">
                    <i
                        class="far fa-arrow-left u-primary-color__text u-hover--grow"
                        @click="$emit('closeConfirmation')"
                    >
                    </i>
                </div>
                <div class="o-account-confirmation__title">
                    {{ scheduleObject.name === 'premium' ? 'Your Premium subscription' : 'Your Platinum subscription' }}
                </div>
                <div class="o-account-confirmation__section">
                    <div class="o-account-confirmation__header">
                        {{ trans('account.plans.plan') }}
                    </div>
                    <div
                        v-if="!loading"
                        class="o-account-confirmation__body"
                    >
                        <div
                            v-if="proration && hasOldAmount"
                            class="o-account-confirmation__proration u-primary-color__bg--light"
                        >
                            The unused amount {{ scheduleObject.symbol }}{{ proration.old_plan.amount.toLocaleString() }} from your current plan was deducted from the total below.
                        </div>
                        <div
                            v-if="trialing"
                            class="o-account-confirmation__proration u-primary-color__bg--light"
                        >
                            Your card won't be charged until your next billing date.
                        </div>

                        <template
                            v-for="(item, index) in planObj"
                            :key="index"
                        >
                            <div
                                v-if="item.condition"
                                class="o-account-confirmation__line"
                            >
                                <p>
                                    {{ item.label }}
                                </p>
                                <p class="u-bold u-primary-color__text">
                                    {{ item.value }}
                                </p>
                            </div>
                        </template>

                        <div v-if="proration || (scheduleObject.discount && promoCode)">
                            <div class="o-account-confirmation__line">
                                * {{ promoCodeDetails }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="o-account-confirmation__section">
                    <div class="o-account-confirmation__header">
                        Payment
                    </div>
                    <div class="o-account-confirmation__body">
                        <div class="o-account-confirmation__line">
                            <p>
                                Method
                            </p>
                            <p class="u-bold u-primary-color__text">
                                {{ isStripe ? 'Card' : 'Paypal' }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="o-account-confirmation__footer">
                    <div
                        class="o-account-confirmation__pay u-primary-color__bg u-hover--grow"
                        @click="upgrade"
                    >
                        {{ isStripe ? 'Upgrade now' : 'Proceed to Paypal' }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { get } from 'vuex-pathify';
import { userStore } from '@js/home-nal/user/userStore.js';

export default {
    name: 'AccountConfirmation',
    emits: ['closeConfirmation'],
    props: {
        paymentType: String,
        scheduleObject: Object,
        promoCode: String,
        card: Object,
        postCode: String,
    },
    data() {
        return {
            loading: false,
            proration: 0,
            trialing: false,
        };
    },
    computed: {
        isStripe() {
            return this.paymentType === 'stripe';
        },
        hasOldAmount() {
            return !!_.get(this.proration, 'old_plan.amount');
        },
        planObj() {
            return {
                promotion: {
                    label: 'Promotion',
                    value: this.promoCode,
                    condition: this.promoCode && this.scheduleObject.discount,
                },
                currency: {
                    label: 'Selected currency',
                    value: this.scheduleObject.currency,
                    condition: true,
                },
                newPlan: {
                    label: 'Total',
                    value: this.scheduleObject.symbol + _.get(this.proration, 'new_plan.amount', '').toLocaleString(),
                    condition: this.proration && this.hasOldAmount,
                },
                proration: {
                    label: 'Unused amount',
                    value: '- ' + this.scheduleObject.symbol + _.get(this.proration, 'old_plan.amount', '').toLocaleString(),
                    condition: this.proration && this.hasOldAmount,
                },
                cost: {
                    label: this.proration || (this.promoCode && this.scheduleObject.discount && this.scheduleObject.period === 'yearly')
                        ? trans('account.period.first-year')
                        : trans('account.period.' + this.scheduleObject.period),
                    value: this.scheduleObject.symbol + this.finalPriceDisplay,
                    condition: true,
                },
            };
        },
        promoCodeDetails() {
            return trans('account.discount.details.' + this.scheduleObject.period);
        },
        finishedRegistration() {
            return userStore.finishedRegistration();
        },
        finalPriceDisplay() {
            const finalPrice = this.proration ? this.proration.total : this.scheduleObject.finalPrice;
            return finalPrice.toLocaleString();
        },
        billing: get('user/billing', false),
    },
    methods: {
        async upgrade() {
            this.loading = true;
            let frequency = this.scheduleObject.period.replace('ly', '');
            let discount = this.scheduleObject.discount && this.scheduleObject.discount[this.scheduleObject.period];

            if (this.billing.service === 'paypal' && this.paymentMethod !== this.billing.service) {
                let reason = 'swap';

                try {
                    await this.$store.dispatch('user/billing/downgrade', { reason: reason, force: true });
                } catch (e) {
                    this.loading = false;
                }
            }

            let destination = this.finishedRegistration ? 'modal' : 'plan';

            try {
                const response = await this.$store.dispatch('user/billing/upgrade', {
                    service: this.paymentType,
                    currency: this.scheduleObject.currency,
                    frequency,
                    coupon: discount,
                    card: this.card,
                    postCode: this.postCode,
                    name: this.scheduleObject.name,
                    destination: destination,
                    proration_date: this.proration?.proration_date,
                });

                if (!response) {
                    this.loading = false;
                    return;
                }

                if (this.paymentType === 'stripe') {
                    this.$emit('closeConfirmation');
                    if (this.$route.name !== 'plan.review') {
                        await this.$router.push({ name: this.$route.name, query: { modal: 'account-welcome' } });
                    } else {
                        await this.$router.push({ name: 'plan.confirmation' });
                    }
                    // for paypal we want it to continue loading until it redirects
                    this.loading = false;
                }
                Cookie.remove('selected-plan');
            } catch (e) {
                this.loading = false;
            }
        },
        async fetchProration() {
            this.loading = true;
            let frequency = this.scheduleObject.period.replace('ly', '');
            let discount = this.scheduleObject.discount && this.scheduleObject.discount[this.scheduleObject.period];

            try {
                let { data, error } = await this.$store.dispatch('user/billing/fetchProration', {
                    service: this.paymentType,
                    currency: this.scheduleObject.currency,
                    frequency,
                    coupon: discount,
                    name: this.scheduleObject.name,
                });

                if (!data) {
                    return 0;
                }

                if (error) {
                    notify(error, 'error');
                }

                return data.proration;
            } catch (e) {
                throw e;
            } finally {
                this.loading = false;
            }
        },
    },
    async created() {
        if (this.billing.plan) {
            let isSameCurrency = this.billing.plan.currency === this.scheduleObject.currency;
            let isSameType = this.billing.type === this.scheduleObject.name;
            let isSameMethod = this.isStripe && this.billing.service === 'stripe';

            if (isSameMethod) {
                if (isSameCurrency && !isSameType) {
                    this.proration = await this.fetchProration();
                }
                // If plan is same type and payment method, new plan will
                // be in trial, therefore customer will not be charged until
                // the end of current's plan grace period.
                if (isSameType) {
                    this.trialing = true;
                }
            }
        }
    },
};
</script>

<style lang="scss" scoped>
    .o-account-confirmation {
        height: 100%;

        &__container {
            align-items: center;
            display: flex;
            height: calc(100% - 200px);
            justify-content: center;
            padding: 0 10px;
        }

        &__box {
            background-color: #fff;
            border: 1px solid #ddd;
            max-width: 100%;
            padding: 50px 20px 30px 20px;
            position: relative;
            width: 300px;
        }

        &__return {
            left: 0;
            padding: 4px 10px;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &__title {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 20px;
            text-align: center;
        }

        &__section {
            margin-bottom: 30px;
        }

        &__header {
            color: #666;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 3px;
            text-transform: uppercase;
        }

        &__body {
            border-bottom: 1px solid #ddd;
            border-top: 1px solid #ddd;
            color: #999;
            padding: 6px 0;
        }

        &__proration {
            color: #333;
            margin-bottom: 10px;
            padding: 10px 15px;
        }

        &__line {
            display: flex;
            justify-content: space-between;

            &:not(:last-child) {
                margin-bottom: 3px;
            }
        }

        &__footer {
            display: flex;
            justify-content: center;
        }

        &__pay {
            color: #fff;
            font-size: 13px;
            min-width: 200px;
            padding: 10px 30px;
            text-align: center;
            text-transform: uppercase;
        }
    }
</style>
