<template>
    <div class="o-account-overlay">
        <div
            v-if="alreadyPlatinum"
            class="o-account-overlay__platinum"
        >
            {{ trans('account.already-member', { planType: planType }) }}
        </div>
        <account-plans
            v-else-if="!showUpgrade"
            @upgrade="onUpgrade"
        >
        </account-plans>
        <account-upgrade
            v-else-if="showUpgrade"
            :chosenPlan="chosenPlan"
            :loggedIn="true"
        >
            <template #viewPlans>
                <div v-if="!isPlatinum && !gracePeriod">
                    <div class="o-account-overlay__button">
                        <div
                            class="o-account-overlay__read u-primary-color__border u-primary-color__text u-hover--grow"
                            @click="showUpgrade = false"
                        >
                            See all plans
                        </div>
                    </div>
                </div>
            </template>
        </account-upgrade>
    </div>
</template>

<script>
import AccountPlans from './account-plans.vue';
import AccountUpgrade from './account-upgrade.vue';

import { get } from 'vuex-pathify';
import knowsUpgrade from '../../vue-mixins/knowsUpgrade.js';

export default {
    name: 'AccountOverlay',
    components: {
        'account-plans': AccountPlans,
        'account-upgrade': AccountUpgrade,
    },
    mixins: [
        knowsUpgrade,
    ],
    props: {
        forcePlan: String,
    },
    data() {
        return {
            showUpgrade: false,
            alreadyPlatinum: false,
        };
    },
    computed: {
        gracePeriod: get('user/billing/subscription_ends_at', false),
    },
    methods: {
        onUpgrade(plan) {
            this.showUpgrade = true;
            this.chosenPlan = plan;
        },
    },
    created() {
        if (this.isPremium) {
            if (this.gracePeriod && !(this.forcePlan === 'platinum')) {
                this.chosenPlan = 'premium';
                this.showUpgrade = true;
            } else {
                this.chosenPlan = 'platinum';
                this.showUpgrade = true;
            }
        } else if (this.isPlatinum) {
            if (this.gracePeriod) {
                this.chosenPlan = 'platinum';
                this.showUpgrade = true;
            } else {
                this.alreadyPlatinum = true;
            }
        } else {
            this.showUpgrade = false;
        }
    },
};
</script>

<style lang="scss" scoped>
    .o-account-overlay {
        &__platinum {
            font-size: 24px;
            margin-top: 120px;
            text-align: center;
        }

        &__button {
            display: flex;
            justify-content: center;
        }

        &__read {
            background-color: #fff;
            border: 1px solid;
            font-size: 12px;
            padding: 6px 16px;
            text-transform: uppercase;
        }

    }
</style>
