import { DEFAULT_CURRENCY } from '@js/core/currencies.js';
import usesCurrencies from '@js/vue-mixins/usesCurrencies.js';

export const currencyList = [
    'GBP',
    'USD',
    'EUR',
    'CHF',
    'AUD',
    'CAD',
    'SEK',
    'ZAR',
    'DKK',
    'MXN',
    'PLN',
];

export default {
    currencyList,
    mixins: [
        usesCurrencies,
    ],
    data() {
        const userStore = window.nal.store.state.user;
        const userCurrency = userStore.settings.currency;

        let planCurrency = null;
        if (userStore.billing.plan) {
            planCurrency = userStore.billing.plan.currency;
        }
        let currencyCookie = Cookie.get('selected-currency');
        const validCurrency = _.includes(currencyList, userCurrency);

        if (typeof currencyCookie === 'undefined') {
            if (planCurrency) {
                currencyCookie = planCurrency;
            } else if (validCurrency) {
                currencyCookie = userCurrency;
            } else {
                currencyCookie = DEFAULT_CURRENCY;
            }
        } else {
            currencyCookie = JSON.parse(currencyCookie);
        }
        return {
            // account currency or currency cookie or default currency
            setCurrency: currencyCookie,
        };
    },
    computed: {
        currencyList() {
            return currencyList;
        },
        setCurrencySymbol() {
            return this.symbol(this.setCurrency);
        },
    },
    methods: {
        setPayCurrency(newCurrency) {
            this.setCurrency = newCurrency;
        },
    },
    watch: {
        'setCurrency': {
            deep: true,
            immediate: true,
            handler(currency) {
                const currencyCookie = JSON.stringify(currency);
                Cookie.set('selected-currency', currencyCookie, { expires: 2 });
            },
        },
    },
};
