// this reflects the values on executives-services.php
const servicesList = [
    {
        value: 'career-story',
        link: 'https://www.notactivelylooking.info/en/services/the-career-story',
        price: {
            GBP: 1500,
            USD: 2073,
            EUR: 1714,
            CHF: 1851,
            AUD: 2679,
            CAD: 2640,
            SEK: 17277,
            ZAR: 30273,
            DKK: 12755,
            MXN: 41539,
            PLN: 7713,
        },
    },
    {
        value: 'job-search',
        link: 'https://www.notactivelylooking.info/en/services/job-search-action-plan-service',
        price: {
            GBP: 795,
            USD: 1000,
            EUR: 886,
            CHF: 950,
            AUD: 1465,
            CAD: 1360,
            SEK: 9335,
            ZAR: 17175,
            DKK: 6605,
            MXN: 22500,
            PLN: 3935,
        },
    },
    {
        value: 'profile-optimization',
        link: 'https://www.notactivelylooking.info/en/en/services/online-profile-optimization',
        price: {
            GBP: 395,
            USD: 495,
            EUR: 440,
            CHF: 475,
            AUD: 730,
            CAD: 676,
            SEK: 4640,
            ZAR: 8535,
            DKK: 3285,
            MXN: 11180,
            PLN: 1955,
        }
    },
    {
        value: 'cv-writing',
        link: 'https://www.notactivelylooking.info/en/en/services/headhunter-friendly-cv',
        price: {
            GBP: 395,
            USD: 495,
            EUR: 440,
            CHF: 475,
            AUD: 730,
            CAD: 676,
            SEK: 4640,
            ZAR: 8535,
            DKK: 3285,
            MXN: 11180,
            PLN: 1955,
        }
    },
    {
        value: 'headhunter-networking',
        link: 'https://www.notactivelylooking.info/en/services/headhunter-networking',
        price: {
            GBP: 925,
            USD: 1160,
            EUR: 1031,
            CHF: 1105,
            AUD: 1705,
            CAD: 1585,
            SEK: 10860,
            ZAR: 19985,
            DKK: 7685,
            MXN: 26180,
            PLN: 4580,
        },
    },
    {
        value: 'career-direction',
        link: 'https://www.notactivelylooking.info/en/services/career-direction',
        price: {
            GBP: 975,
            USD: 1225,
            EUR: 1087,
            CHF: 1165,
            AUD: 1795,
            CAD: 1670,
            SEK: 11450,
            ZAR: 21065,
            DKK: 8100,
            MXN: 27595,
            PLN: 4825,
        },
    },
    {
        value: 'confident-networking',
        link: 'https://www.notactivelylooking.info/en/services/confident-networking',
        price: {
            GBP: 600,
            USD: 755,
            EUR: 670,
            CHF: 720,
            AUD: 1105,
            CAD: 1030,
            SEK: 7045,
            ZAR: 12965,
            DKK: 4985,
            MXN: 16985,
            PLN: 2970,
        },
    },
    {
        value: 'boardroom-ready',
        link: 'https://www.notactivelylooking.info/en/services/boardroom-ready',
        price: {
            GBP: 9600,
            USD: 12035,
            EUR: 10695,
            CHF: 11445,
            AUD: 17675,
            CAD: 16425,
            SEK: 112710,
            ZAR: 207405,
            DKK: 79740,
            MXN: 271700,
            PLN: 47515,
        }
    },
    {
        value: 'becoming-ned',
        link: 'https://www.notactivelylooking.info/en/services/becoming-a-non-executive-director',
        price: {
            GBP: 2000,
            USD: 2510,
            EUR: 2230,
            CHF: 2385,
            AUD: 3682,
            CAD: 3425,
            SEK: 23480,
            ZAR: 43210,
            DKK: 11615,
            MXN: 56605,
            PLN: 9900,
        },
    },
    {
        value: 'combo-branding',
        link: 'https://www.notactivelylooking.info/en/services/personal-branding-package',
        price: {
            GBP: 1795,
            USD: 2491,
            EUR: 2099,
            CHF: 2256,
            AUD: 3386,
            CAD: 3114.55,
            SEK: 21399,
            ZAR: 35917,
            DKK: 15613,
            MXN: 49527.64,
            PLN: 9564,
        }
    },
];

export default {
    mixins: [
        require ('./knowsUpgrade.js').default,
    ],
    data() {
        return {
            servicesList: servicesList,
        }
    },
    computed: {
        servicesPrices() {
            return servicesList.map(service => {
                return service.price[this.setCurrency]
            });
        },
    },
    methods: {
        servicePrice(service) {
            return service.price[this.setCurrency].toLocaleString();
        },
    },
    created() {

    }
}
