<template>
    <div class="o-account-schedule">
        <!--        <div-->
        <!--            v-if="scheduleObject.name === 'premium'"-->
        <!--            class="o-account-schedule__select"-->
        <!--        >-->
        <!--            <div class="o-account-schedule__container">-->
        <!--                <div class="o-account-schedule__perk u-primary-color__text u-primary-color__border"-->
        <!--                >-->
        <!--                    <div class="o-account-schedule__bubble">-->
        <!--                        2 months free-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="o-account-schedule__duration">-->
        <!--                    <div class="o-account-schedule__selection u-pointer"-->
        <!--                        :class="{ ' o-account-schedule__selection&#45;&#45;schedule u-primary-color__bg' : scheduleObject.period === 'monthly' }"-->
        <!--                        @click="$emit('newSchedule', 'monthly')"-->
        <!--                    >-->
        <!--                        {{ trans('account.monthly') }}-->
        <!--                    </div>-->
        <!--                    <div class="o-account-schedule__selection u-pointer"-->
        <!--                        :class="{ 'o-account-schedule__selection&#45;&#45;schedule u-primary-color__bg' : scheduleObject.period === 'yearly' }"-->
        <!--                        @click="$emit('newSchedule', 'yearly')"-->
        <!--                    >-->
        <!--                        {{ trans('account.yearly') }}-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
        <div class="o-account-schedule__wrapper">
            <div class="o-account-schedule__holder">
                <div class="o-account-schedule__pricing">
                    <span class="o-account-schedule__symbol">
                        {{ scheduleObject.symbol }}
                    </span>
                    <span class="o-account-schedule__price">
                        {{ scheduleObject.price.toLocaleString() }}
                    </span>
                    <span
                        v-if="scheduleObject.name !== 'platinum'"
                        class="o-account-schedule__period"
                    >
                        &nbsp;/ {{ timePeriod }}
                    </span>
                </div>
                <div
                    v-blur="closeCurrencyDropdown"
                    class="o-account-schedule__switcher"
                >
                    <div
                        class="o-account-schedule__circle u-hover--grow u-primary-color__border u-primary-color__text"
                        @click="currencyDropdown = !currencyDropdown"
                    >
                        <span class="o-account-schedule__selected">
                            {{ scheduleObject.currency }}
                        </span>
                        <i
                            class="fas o-account-schedule__angle"
                            :class="currencyDropdown ? 'fa-angle-up' : 'fa-angle-down'"
                        >
                        </i>
                    </div>
                    <basic-popup
                        v-show="currencyDropdown"
                        :popupStyle="{ top: '36px', width: '60px', right: '0', padding: '0', border: '0', 'border-radius': '10px' }"
                        triangleClass="u-hidden"
                    >
                        <div class="o-account-schedule__dropdown u-primary-color__border u-primary-color__text">
                            <div
                                v-for="(code, index) in currencyList"
                                :key="index"
                                class="o-account-schedule__code u-hover--grow"
                                @click="setPayCurrency(code)"
                            >
                                {{ code }}
                            </div>
                        </div>
                    </basic-popup>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import knowsUpgrade from '@js/executives/vue-mixins/knowsUpgrade.js';

export default {
    name: 'AccountSchedule',
    mixins: [
        knowsUpgrade,
    ],
    emits: ['setPayCurrency'],
    props: {
        currencyList: Array,
        setCurrency: String,
        scheduleObject: Object,
    },
    data() {
        return {
            currencyDropdown: false,
        };
    },
    computed: {
        timePeriod() {
            // return this.scheduleObject.period === 'yearly' ? trans('account.year') : trans('account.month');
            return trans('account.year');
        },
    },
    methods: {
        closeCurrencyDropdown() {
            this.currencyDropdown = false;
        },
        setPayCurrency(code) {
            this.closeCurrencyDropdown();
            this.$emit('setPayCurrency', code);
        },
    },
};
</script>

<style lang="scss" scoped>
    .o-account-schedule {
        &__select {
            display: flex;
            font-size: 13px;
            font-weight: 600;
            margin-bottom: 30px;
        }

        &__perk {
            bottom: 12px;
            left: 40px;
            position: relative;
        }

        &__bubble {
            background-color: #fff;
            border: 2px solid;
            border-radius: 18px;
            font-size: 13px;
            font-weight: 600;
            padding: 6px 12px;
            position: relative;
            text-align: center;

            &::before {
                border-bottom: 8px solid transparent !important;
                border-left: 8px solid transparent !important;
                border-right: 8px solid transparent !important;
                border-top: 8px solid #fff;
                bottom: -16px;
                content: "";
                height: 0;
                position: absolute;
                right: calc(50% - 8px);
                width: 0;
                z-index: 1;
            }

            &::after {
                border-bottom: 8px solid transparent !important;
                border-left: 8px solid transparent !important;
                border-right: 8px solid transparent !important;
                border-top: 8px solid;
                bottom: -18px;
                content: "";
                height: 0;
                position: absolute;
                right: calc(50% - 8px);
                width: 0;
                z-index: 0;
            }
        }

        &__duration {
            display: flex;
        }

        &__selection {
            border-radius: 12px;
            margin: 0 5px;
            padding: 2px 10px;

            &--schedule {
                color: #fff;
            }
        }

        &__wrapper {
            display: inline-flex;
            justify-content: center;
            width: 100%;
        }

        &__holder {
            position: relative;
            width: 100%;
        }

        &__pricing {
            background-color: #fff;
            display: flex;
            justify-content: center;
            max-width: 300px;
            padding: 20px 10px;
            width: 100%;
        }

        &__symbol {
            font-size: 27px;
            font-weight: 600;
            margin-right: 4px;
        }

        &__price {
            font-size: 50px;
            line-height: 55px;
        }

        &__period {
            align-self: flex-end;
            font-size: 17px;
            margin-bottom: 4px;
        }

        &__switcher {
            bottom: -12px;
            position: absolute;
            right: -10px;
        }

        &__circle {
            align-items: center;
            background-color: #fff;
            border: 1px solid;
            border-radius: 20px;
            border-width: 2px;
            display: flex;
            font-size: 14px;
            font-weight: 600;
            padding: 2px 5px;
        }

        &__selected {
            display: block;
            min-width: 32px;
            width: 32px;
        }

        &__angle {
            margin-left: 3px;
        }

        &__dropdown {
            border: 2px solid;
            border-radius: 2px;
            font-size: 14px;
            padding: 4px 6px;
            text-align: center;
        }

        &__code {
            font-weight: 600;
        }

        @media (max-width: 880px) {
            &__holder {
                width: 300px;
            }
        }

        @media (max-width: 400px) {
            &__holder {
                width: 270px;
            }

            &__symbol {
                font-size: 20px;
            }

            &__price {
                font-size: 40px;
            }
        }

        @media (max-width: 335px) {
            &__holder {
                width: 250px;
            }

            &__switcher {
                right: -5px;
            }
        }
    }
</style>
