<template>
    <div class="c-stripe-input">
        <div ref="stripeField"></div>
        <slot></slot>
        <transition name="t-fade">
            <alert-tooltip
                v-if="error"
                :text="error"
            ></alert-tooltip>
        </transition>
    </div>
</template>

<script>
import AlertTooltip from '../popups-tooltips/alert-tooltip.vue';

export default {
    name: 'StripeInputField',
    inject: ['elements'],
    components: {
        AlertTooltip,
    },
    emits: ['complete', 'ready'],
    props: {
        name: null,
        customClass: String,
        inputClass: String,
        field: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            error: '',
            element: null,
        };
    },
    computed: {
    },
    mounted() {
        const card = this.element = this.elements.create(this.field, {
            classes: {
                base: (this.inputClass || '') + ' ' + 'c-stripe-input__input',
                focus: 'u-primary-color__box',
            },
            style: {
                base: {
                    '::placeholder': {
                        color: '#ccc',
                        fontStyle: 'italic',
                    },
                    fontSize: '16px',
                    fontFamily: '"Open Sans"',
                    color: '#333',
                },
            },
            ...this.$attrs,
        });
        card.mount(this.$refs.stripeField);
        card.addEventListener('change', event => {
            if (event.complete) {
                this.$emit('complete');
            }
            this.error = event.error ? event.error.message : '';
        });
        card.addEventListener('ready', () => this.$emit('ready', this.element));
    },
    unmounted() {
        this.element.destroy();
    },
};

</script>

<style lang="scss">

.c-stripe-input {
    position: relative;

    &__input {
        appearance: none;
        background: #fafafa;
        border: 1px solid #ddd;
        border-radius: 0;
        color: #333;
        font-size: 16px;
        font-weight: 300;
        height: 40px;
        padding: 8px 10px;
        transition: all 0.2s ease-in;
        width: 100%;

        &::placeholder {
            color: #aaa;
            font-weight: 300;
        }

        &--italic::placeholder {
            font-style: italic;
        }
    }
}

</style>
