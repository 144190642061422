<template>
    <div class="o-account-plan-table">

        <div class="o-account-plan-table__large">

            <div class="o-account-plan-table__features">
                <div
                    v-for="(feature, index) in [1,2,3,4,5,6,7,8,9]"
                    :key="index"
                    class="o-account-plan-table__feature"
                >
                    {{ ( trans('account.plans.features.feature' + feature )) }}
                </div>
            </div>

            <div class="u-flex">
                <div
                    v-for="plan in plans"
                    :key="plan"
                    class="o-account-plan-table__plan"
                    :class="{ 'u-relative': isPlatinum }"
                >
                    <div
                        v-if="isPlatinum && plan === 'platinum'"
                        class="o-account-plan-table__tag-container"
                    >
                        <div class="o-account-plan-table__tag u-primary-color__bg">
                            {{ trans('account.plans.current') }}
                        </div>
                    </div>

                    <div class="o-account-plan-table__header">
                        <div class="o-account-plan-table__name">
                            {{ ( trans('account.plans.' + plan )) }}
                        </div>

                        <div class="o-account-plan-table__pricing">
                            <div
                                v-if="plan === 'free'"
                                class="o-account-plan-table__price"
                            >
                                {{ trans('account.free.cost') }}
                            </div>
                            <div
                                v-else
                                class="o-account-plan-table__annual"
                            >
                                <span class="o-account-plan-table__symbol">
                                    {{ setCurrencySymbol }}
                                </span>
                                <span class="o-account-plan-table__price">
                                    {{ plansPrices[plan].toLocaleString() }}
                                </span>
                                <span
                                    v-if="plan !== 'platinum'"
                                    class="o-account-plan-table__period"
                                >
                                    / {{ ( trans('account.year')) }}
                                </span>
                            </div>
                            <!--                            <div class="o-account-plan-table__monthly"-->
                            <!--                                v-if="plan === 'premium'">-->
                            <!--                                    Or-->
                            <!--                                    <span class="o-account-plan-table__monthly-symbol">-->
                            <!--                                        {{ setCurrencySymbol }}-->
                            <!--                                    </span>-->
                            <!--                                    <span class="o-account-plan-table__monthly-price">-->
                            <!--                                        {{ monthlyPrices[plan] }}-->
                            <!--                                    </span>-->
                            <!--                                    <span class="o-account-plan-table__monthly-period">-->
                            <!--                                        &nbsp/ {{ ( trans('account.mo')) }}-->
                            <!--                                    </span>-->
                            <!--                            </div>-->
                        </div>
                    </div>

                    <div :class="{ 'u-bot-margin--20': isPlatinum }">
                    </div>

                    <div
                        v-if="!isPlatinum"
                        class="o-account-plan-table__cta"
                    >
                        <div
                            v-if="isInactiveButton(plan)"
                            class="o-account-plan-table__inactive u-primary-color__border u-primary-color__text"
                            :class="secondaryButtonClass"
                        >
                            {{ planType === plan ? trans('account.plans.current') : trans('account.plans.basic') }}
                        </div>
                        <div
                            v-else
                            class="o-account-plan-table__active u-primary-color__bg u-hover--grow"
                            :class="primaryButtonClass"
                            @click="selectPlan(plan)"
                        >
                            {{ trans('account.plans.choose') }}
                        </div>
                    </div>

                    <div
                        v-for="(benefit, index) in benefits[plan]"
                        :key="index"
                        class="o-account-plan-table__benefit"
                    >
                        <div
                            v-if="checkBooleanType(benefit)"
                            class="o-account-plan-table__item"
                        >
                            <i :class="displayCheck(benefit)">
                            </i>
                        </div>

                        <div
                            v-else
                            class="o-account-plan-table__item"
                        >
                            {{ benefit }}
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- responsive table -->
        <div class="o-account-plan-table__resp">

            <div class="o-account-plan-table__tabs">
                <div
                    v-for="plan in plans"
                    :key="plan"
                    class="o-account-plan-table__tab u-bold u-hover"
                    :class="activeTab === plan ? 'o-account-plan-table__tab--active u-primary-color__text' : 'u-primary-color__text--hover'"
                    @click="activeTab = plan"
                >
                    {{ ( trans('account.plans.' + plan )) }}
                </div>
            </div>

            <div class="o-account-plan-table__header">

                <div
                    v-if="isPlatinum && activeTab === 'platinum'"
                    class="o-account-plan-table__resp-tag-container"
                >
                    <!-- :class="{ 'u-relative' : isPlatinum }" for tag-container -->
                    <div class="o-account-plan-table__tag u-primary-color__bg">
                        {{ trans('account.plans.current') }}
                    </div>
                </div>

                <div :class="{ 'u-bot-margin--20': isPlatinum }">
                </div>

                <div class="o-account-plan-table__name">
                    {{ ( trans('account.plans.' + activeTab )) }}
                </div>

                <div class="o-account-plan-table__pricing">
                    <div
                        v-if="activeTab === 'free'"
                        class="o-account-plan-table__price"
                    >
                        FREE
                    </div>
                    <div
                        v-else
                        class="o-account-plan-table__annual"
                    >
                        <span class="o-account-plan-table__symbol">
                            {{ setCurrencySymbol }}
                        </span>
                        <span class="o-account-plan-table__price">
                            {{ plansPrices[activeTab].toLocaleString() }}
                        </span>
                        <span
                            v-if="activeTab !== 'platinum'"
                            class="o-account-plan-table__period"
                        >
                            / {{ ( trans('account.year')) }}
                        </span>
                    </div>
                    <!--                    <div class="o-account-plan-table__monthly"-->
                    <!--                        v-if="activeTab === 'premium'"-->
                    <!--                    >-->
                    <!--                        {{ ( trans('account.or')) }}-->
                    <!--                        <span class="o-account-plan-table__monthly-symbol">-->
                    <!--                            {{ setCurrencySymbol }}-->
                    <!--                        </span>-->
                    <!--                        <span class="o-account-plan-table__monthly-price">-->
                    <!--                            {{ monthlyPrices[activeTab] }}-->
                    <!--                        </span>-->
                    <!--                        <span class="o-account-plan-table__monthly-period">-->
                    <!--                            &nbsp/ {{ ( trans('account.mo')) }}-->
                    <!--                        </span>-->
                    <!--                    </div>-->
                </div>

                <div
                    v-if="!isPlatinum"
                    class="o-account-plan-table__cta"
                >
                    <div
                        v-if="isInactiveButton(activeTab)"
                        class="o-account-plan-table__inactive u-primary-color__border u-primary-color__text"
                        :class="secondaryButtonClass"
                    >
                        {{ planType === activeTab ? trans('account.plans.current') : trans('account.plans.basic') }}
                    </div>
                    <div
                        v-else
                        class="o-account-plan-table__active u-primary-color__bg u-hover--grow"
                        :class="primaryButtonClass"
                        @click="selectPlan(activeTab)"
                    >
                        {{ trans('account.plans.choose') }}
                    </div>
                </div>

            </div>

            <div class="o-account-plan-table__resp-plan">
                <div
                    v-for="(benefit, index) in benefits[activeTab]"
                    :key="index"
                    class="o-account-plan-table__benefit"
                >
                    <div class="o-account-plan-table__feature">
                        {{ ( trans('account.plans.features.' + index )) }}
                    </div>
                    <div
                        v-if="checkBooleanType(benefit)"
                        class="o-account-plan-table__item"
                    >
                        <i :class="displayCheck(benefit)">
                        </i>
                    </div>

                    <div
                        v-else
                        class="o-account-plan-table__item"
                    >
                        {{ benefit }}
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>

const plans = [
    'free',
    'premium',
    'platinum',
];

const benefits = {
    free: {
        careerAudit: false,
        careerStory: false,
        coach: false,
        support: 'Live chat & email',
        liveWebinars: true,
        onboarding: 'Free webinar',
        library: true,
        networking: 'Free live events',
        connections: 'Limited',
    },
    premium: {
        careerAudit: true,
        careerStory: false,
        coach: false,
        support: 'Career Advisor',
        liveWebinars: true,
        onboarding: '1:1 session',
        library: true,
        networking: 'Priority access',
        connections: 'Unlimited',
    },
    platinum: {
        careerAudit: true,
        careerStory: true,
        coach: true,
        support: 'Senior Career Coach',
        liveWebinars: true,
        onboarding: '1:1 session',
        library: true,
        networking: 'Priority access',
        connections: 'Unlimited',
    },
};

import { default as providesPlansCurrencies, priceList } from '../../vue-mixins/providesPlansCurrencies.js';
import providesServices from '../../vue-mixins/providesServices.js';
import knowsUpgrade from '../../vue-mixins/knowsUpgrade.js';

export default {
    components: {

    },
    mixins: [
        providesPlansCurrencies,
        providesServices,
        knowsUpgrade,
    ],
    emits: ['upgrade'],
    props: {
        primaryButtonClass: String,
        secondaryButtonClass: String,
        setCurrency: String,
        setCurrencySymbol: String,
    },
    data() {
        return {
            activeTab: '',
        };
    },
    computed: {
    },
    methods: {
        displayCheck(benefit) {
            return benefit === true
                ? 'o-account-plan-table__checkmark fas fa-check u-primary-color__text'
                : 'o-account-plan-table__times fas fa-times';
        },
        selectPlan(plan) {
            this.$emit('upgrade', plan);
        },
        findService(plan, service) {
            const serviceObj = _.find(this.servicesList, ['value', service]);
            const discount = this.getDiscount(plan);
            const basePrice = serviceObj.price[this.setCurrency];
            const discountedPrice = discount * basePrice;
            return discountedPrice.toFixed(2);
        },
        getDiscount(plan) {
            if (plan === 'free') {
                return 0.9;
            }
            if (plan === 'premium') {
                return 0.8;
            }
            return 1;
        },
        checkBooleanType(benefit) {
            return _.isBoolean(benefit);
        },
        isInactiveButton(plan) {
            return this.planType === plan || plans.indexOf(plan) < plans.indexOf(this.planType);
        },
    },
    created() {
        this.benefits = benefits;
        this.priceList = priceList;
        this.plans = plans;
        this.activeTab = !this.planType ? 'free' : (this.isFree ? 'premium' : 'platinum');
    },
};
</script>

<style lang="scss" scoped>

    .o-account-plan-table {
        &__large {
            align-items: flex-end;
            display: flex;
            justify-content: center;
            text-align: center;
        }

        &__features {
            display: flex;
            flex-direction: column;
            text-align: left;
        }

        &__feature {
            display: inline-block;
            margin: 12px 0;
            margin-right: 20px;
        }

        &__plan {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__tag-container {
            position: absolute;
            top: -40px;
        }

        &__tag {
            border-radius: 20px;
            color: white;
            font-size: 12px;
            font-weight: 600;
            margin: 5px;
            padding: 2px 10px;
        }

        &__header {
            align-items: center;
            display: flex;
            flex-direction: column;
        }

        &__name {
            align-items: center;
            display: flex;
            font-weight: 600;
            margin-bottom: 8px;
            min-height: 64px;
            text-align: center;
        }

        &__pricing {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 60px;
        }

        &__annual {
            font-weight: 600;
            text-align: center;
        }

        &__symbol {
            margin-right: 4px;
        }

        &__price {
            font-weight: 600;
            line-height: 35px;
        }

        &__period {
            align-self: flex-end;
            color: #666;
            font-weight: 500;
        }

        //&__monthly {
        //    color: #666;
        //    display: flex;
        //    justify-content: center;
        //    font-size: 14px;
        //    margin-top: 3px;
        //}
        //
        //&__monthly-symbol {
        //    margin: 0 4px;
        //}
        //
        //&__monthly-period {
        //
        //}

        &__inactive {
            &:hover {
                cursor: default;
            }
        }

        &__benefit {
            align-items: center;
            display: flex;
        }

        &__item {
            align-items: center;
            color: #707070;
            display: flex;
            justify-content: center;
            margin: 12px 0;
            text-align: center;
        }

        &__item-symbol {
            margin-right: 4px;
        }

        &__checkmark {
        }

        &__times {
            color: #777;
        }

        &__resp {
            display: none;

            &-tag-container {
                position: relative;
                top: 10px;
            }

            &-plan {
                align-items: center;
                display: flex;
                flex-direction: column;
            }
        }

        &__tabs {
            display: flex;
            justify-content: center;
            margin: 10px 0 30px;
        }

        &__tab {
            align-items: center;
            color: #777;
            display: flex;
            justify-content: center;
            margin: 0 10px;
            padding: 4px 10px;
            text-align: center;
            width: 110px;

            &--active {
                border-bottom: 2px solid;
            }
        }

        @media (max-width: 400px) {
            &__tab {
                margin: 0;
            }
        }

    }

</style>
