import { loadStripe } from '@stripe/stripe-js';

const STRIPE_KEY = process.env.NODE_ENV === 'production' ? 'pk_live_jmLAw8Cq4AGNe5uAPkTRGluh' : 'pk_test_ynflpjGwx6MhzpYGk0O6y2jY';

let stripe = null;

function getStripe() {
    if (!stripe) {
        stripe = loadStripe(STRIPE_KEY);
    }
    return stripe;
}

export default getStripe;